<template>
  <div>
    <div class="header">
      <a href="/smm" class="home">
        Центр AI-маркетинга :: SMM AI-конструктор
      </a>
      <a href="https://ai-marketing.space/smm_lk" class="home"
        style="display: block; margin-left: auto; margin-right: 30px;">Личный кабинет</a>
      <span>
        <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm" style="border: none"
          class="home">Помощь<help-circle-icon class="fea icon-sm"></help-circle-icon></a>
      </span>
      <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>
        <div class="bg-white p-1 rounded box-shadow">
          <div class="text-muted mb-2" style="font-size: small">
            <p>
              SMM AI-конструктор - это инструмент, который позволяет буквально
              по нескольким тезисам сгенерировать пост с полноценным текстом и
              изображениями, учитывающими LOVEMARK-концепцию бренда.
            </p>
            <p>Для этого:</p>
            <p>
              1. Введите ключевые смысловые тезисы / идею вашего поста.
              Например: "рекламный пост кредитных карт для школьных учителей".
            </p>
            <p>
              2. Выберите уровень креатива (<0.5 - низкий, 0.5-0.6 - средний,>0.6 - высокий), стиль текста (с учетом
                LOVEMARK-концепции или
                без), стиль изображения (если оно нужно).
            </p>
            <p>
              3. Запустите генерацию. При необходимости вы можете повторить
              итерацию для каждой из компонент (текст / картинка) или целиком.
            </p>

            <hr>
            </hr>

            <p>
              Для текстов оценикается конверсия лайков <thumbs-up-icon
                class="fea icon mr-2 feather-small"></thumbs-up-icon>, репостов
              <repeat-icon class="fea icon mr-2 feather-small"></repeat-icon> и комментариев
              <message-circle-icon class="fea icon mr-2 feather-small"></message-circle-icon>
            </p>
            <p>
              Если конверсия низкая - она подсвечивается красным <thumbs-up-icon class="fea icon mr-2 feather-small"
                style="color: red;"></thumbs-up-icon>
            </p>
            <p>
              Если конверсия средняя - она подсвечивается оранжевым <thumbs-up-icon class="fea icon mr-2 feather-small"
                style="color: orange;"></thumbs-up-icon>
            </p>
            <p>
              Если конверсия выслкая - она подсвечивается зеленым <thumbs-up-icon class="fea icon mr-2 feather-small"
                style="color: green;"></thumbs-up-icon>
            </p>

          </div>
        </div>
      </b-modal>
    </div>

    <div class="mainsmm">
      <h1 class="title titleBold">Тезисы или идеи для генерации текста</h1>
      <textarea ref="textarea" class="form-control prompt__textarea mt-2"
        placeholder="Введите тезисы (новый тезис - новая строка):" v-model="prompt_str" />
      <div class="row">
        <div class="col-3">
          <label for="">Уровень креатива</label>
          <vs-slider id="creative_slider" color="success" text-fixed="%" v-model="input_parameters.temperature" />
        </div>
        <div class="col-3">
          <vs-select label="Стиль текста" v-model="input_parameters.styleText">
            <vs-select-item id="text_style" :key="index" :value="item" :text="item"
              v-for="(item, index) in styleTextArr" />
          </vs-select>
        </div>
        <div class="col-3">
          <label for="">Добавить картинки</label>
          <vs-switch color="success" v-model="input_parameters.withImage" />
        </div>
        <div class="col-3" v-if="input_parameters.withImage">
          <vs-select label="Стиль изображения" v-model="input_parameters.styleImage">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in styleImageArr" />
          </vs-select>
        </div>
      </div>
      <div v-if="stopFlags[0]" class="flex-container flex-start">
        <button class="prompt__btn prompt__btn-submit" @click="runGenerateAll()">
          Начать заново
        </button>
      </div>
      <div v-else class="flex-container flex-start">
        <button class="prompt__btn prompt__btn-stop" @click="stopGenerateAll">
          Остановить генерацию
        </button>
      </div>
      <p v-if="(promptIndex[0] > 0) & stopFlags[0]" class="flex-container flex-start">
        Текущий номер тезиса: {{ promptIndex[0] }} (продолжение начнется с этого
        тезиса). Текст тезиса:
        {{ prompt[promptIndex[0]] }}
      </p>
      <div class="row">
        <div class="col-12">
          <br />
          <h1 class="title titleBold">AI-генерация текста</h1>
          <br />
        </div>
        <div class="col-6" v-for="(name, index) in modelTextFullNames">
          <div class="generation__textarea mt-2">
            <div class="model__title">
              <div class="row">
                <div class="col-10">
                  <p align="center">
                    <b-spinner type="grow" small v-if="stopFlags[modelFull2Index[name]] === false"></b-spinner>
                    {{ name }}
                  </p>
                </div>
                <div class="col-2">
                  <a @click="copy_to_clipboard(name)"><copy-icon class="fea icon-sm mr-2 feather-small"></copy-icon></a>
                  <a @click="download(name)"><download-icon class="fea icon mr-2 feather-small"></download-icon></a>
                  <a @click="
                    runGenerateModel(modelFull2Index[name], Full2Short[name])
                    "><refresh-cw-icon class="fea icon feather-small"></refresh-cw-icon></a>
                </div>
              </div>
            </div>
            <h5>{{ article_strs[parseInt(modelFull2Index[name])] }}</h5>


            <div v-if="sml_metrics[parseInt(modelFull2Index[name])]">
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][0] == 0">
                <thumbs-up-icon class="fea icon mr-2 feather-small" style="color: red;"></thumbs-up-icon>
              </span>
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][0] == 1">
                <thumbs-up-icon class="fea icon mr-2 feather-small" style="color: orange;"></thumbs-up-icon>
              </span>
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][0] == 2">
                <thumbs-up-icon class="fea icon mr-2 feather-small" style="color: green;"></thumbs-up-icon>
              </span>

              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][1] == 0">
                <repeat-icon class="fea icon mr-2 feather-small" style="color: red;"></repeat-icon>
              </span>
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][1] == 1">
                <repeat-icon class="fea icon mr-2 feather-small" style="color: orange;"></repeat-icon>
              </span>
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][1] == 2">
                <repeat-icon class="fea icon mr-2 feather-small" style="color: green;"></repeat-icon>
              </span>

              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][1] == 0">
                <message-circle-icon class="fea icon mr-2 feather-small" style="color: red;"></message-circle-icon>
              </span>
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][1] == 1">
                <message-circle-icon class="fea icon mr-2 feather-small" style="color: orange;"></message-circle-icon>
              </span>
              <span v-if="sml_metrics[parseInt(modelFull2Index[name])][1] == 2">
                <message-circle-icon class="fea icon mr-2 feather-small" style="color: green;"></message-circle-icon>
              </span>
            </div>

            <div class="fb-chat" v-if="stopFlags[modelFull2Index[name]] === false">
              <div class="fb-chat__bubbles">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="input_parameters.withImage">
        <div class="row mt-3">
          <div class="col-12">
            <h1 class="title titleBold">AI-генерация картинки</h1>
            <br />
            <p class="model__title">
              Автоматически созданный промпт для генерации изображений (можно
              редактировать)
            </p>
            <textarea ref="textarea" class="form-control prompt__textarea mt-2"
              placeholder="Это промпт для генерации изображений" v-model="image_prompt_str" />
            <div v-if="stopFlags[2]" class="flex-container flex-start">
              <button class="prompt__btn prompt__btn-submit" @click="runGenerateAllImage()">
                Начать генерацию картинок
              </button>
            </div>
            <div v-else class="flex-container flex-start">
              <button class="prompt__btn prompt__btn-stop" @click="stopGenerateAllImage()">
                Остановить генерацию картинок
              </button>
            </div>
          </div>
          <div class="col-6" v-for="(name, index) in modelImageFullNames">
            <div class="generation__textarea mt-2">
              <div class="model__title">
                <div class="row">
                  <div class="col-10">
                    <p align="center">
                      <b-spinner type="grow" small v-if="stopFlags[modelFull2Index[name]] === false"></b-spinner>
                      {{ name }}
                    </p>
                  </div>
                  <div class="col-2">
                    <a @click="copy_to_clipboard(name)"><copy-icon class="fea icon-sm mr-2 feather-small"></copy-icon></a>
                    <a @click="download(name)"><download-icon class="fea icon mr-2 feather-small"></download-icon></a>
                    <a @click="
                      runGenerateModel(
                        modelFull2Index[name],
                        Full2Short[name]
                      )
                      "><refresh-cw-icon class="fea icon feather-small"></refresh-cw-icon></a>
                  </div>
                </div>
              </div>
              <img :src="article_strs[parseInt(modelFull2Index[name])]" style="max-width: 100%; max-height: 100%" />
              <div class="fb-chat" v-if="stopFlags[modelFull2Index[name]] === false">
                <div class="fb-chat__bubbles">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { height } from "dom7";
import store from "@/store/store";
import {
  SquareIcon,
  DownloadIcon,
  CopyIcon,
  RefreshCwIcon,
  HelpCircleIcon,
  WatchIcon,
  MessageCircleIcon,
  ThumbsUpIcon,
  RepeatIcon
} from "vue-feather-icons";

export default {
  components: {
    SquareIcon,
    DownloadIcon,
    CopyIcon,
    RefreshCwIcon,
    HelpCircleIcon,
    MessageCircleIcon,
    ThumbsUpIcon,
    RepeatIcon
  },

  name: "SMM",

  data() {
    return {
      input_parameters: {
        temperature: 50,
        withImage: false,
        styleImage: "no_style",
        styleText: "",
        prompt: "",
        image_prompt: "",
        user_id: store.state.user_id,
      },
      styleImageArr: [],
      styleTextArr: [],
      article_strs: {
        0: "",
        1: "",
        2: "",
        3: "",
        // 4: "",
      },
      sml_metrics: {
        0: [],
        1: [],
        2: [],
        3: [],
        // 4: [],
      },
      stopFlags: {
        0: true,
        1: true,
        2: true,
        3: true,
        // 4: true,
      },
      prompt: [],
      hash: "",
      prompt_str: "",
      image_prompt_str: "",
      modelText: [
        { full: "GigaChat", short: "giga" },
        { full: "GPT-4", short: "cgpt" },
        // { full: "Mistral", short: "mistral" },
      ],
      modelImage: [
        { full: "SDXL", short: "sdxl" },
        { full: "Kandinsky", short: "kandinsky" },
      ],
    };
  },
  watch: {
    prompt_str: function (newVal, oldVal) {
      this.$refs.textarea.style.height = "auto";
      this.$refs.textarea.style.height =
        this.$refs.textarea.scrollHeight + 20 + "px";
      this.input_parameters.prompt = newVal;
    },
    image_prompt_str: function (newVal, oldVal) {
      this.$refs.textarea.style.height = "auto";
      this.$refs.textarea.style.height =
        this.$refs.textarea.scrollHeight + 20 + "px";
      this.input_parameters.image_prompt = newVal;
    }
  },
  async created() {
    console.log(this.$cookies.get("user"));
    this.hash = this.$CryptoJS.MD5("Hi There!" + new Date()).toString();
    console.log("hash = " + this.hash);

    document
      .getElementById("theme-opt")
      .setAttribute("href", "/css/style-null.css");
    document
      .getElementById("color-opt")
      .setAttribute("href", "/css/colors/default-null.css");

    let i = 0;

    this.modelTextFullNames = [];
    this.modelImageFullNames = [];
    this.modelTextShortNames = [];
    this.modelImageShortNames = [];

    this.modelFull2Index = {};
    this.modelShort2Index = {};
    this.Full2Short = {};
    this.promptIndex = {};

    this.modelText.forEach((v) => {
      this.modelTextFullNames.push(v.full);
      this.modelTextShortNames.push(v.short);
      this.modelFull2Index[v.full] = i;
      this.modelShort2Index[v.short] = i;
      this.promptIndex[i] = 0;
      this.Full2Short[v.full] = v.short;
      i += 1;
    });

    this.modelImage.forEach((v) => {
      this.modelImageFullNames.push(v.full);
      this.modelImageShortNames.push(v.short);
      this.modelFull2Index[v.full] = i;
      this.modelShort2Index[v.short] = i;
      this.promptIndex[i] = 0;
      this.Full2Short[v.full] = v.short;
      i += 1;
    });

    const self = this;
    this.axios({
      method: "post",
      url: this.$hostname_ba + "/smm/get_smm_params",
    }).then(function (response) {
      self.styleImageArr = response.data["image_styles"];
      self.styleTextArr = response.data["text_styles"];
      self.input_parameters.styleImage = response.data["default_image_style"];
      self.input_parameters.styleText = response.data["default_text_style"];
      self.input_parameters.temperature = response.data["default_temperature"];
    });
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async runGenerateAll() {
      await this.runGenerateAllText();
      if (this.input_parameters.withImage) {
        this.runGenerateAllImage();
      }
    },
    async runGenerateAllText() {
      console.log("launch runGenerateAllText");

      this.article_strs[0] = "";
      this.article_strs[1] = "";
      this.article_strs[2] = "";
      this.article_strs[3] = "";
      // this.article_strs[4] = "";
      this.sml_metrics[0] = [-1, -1, -1];
      this.sml_metrics[1] = [-1, -1, -1];
      this.sml_metrics[2] = [-1, -1, -1];
      this.sml_metrics[3] = [-1, -1, -1];
      // this.sml_metrics[4] = [-1, -1, -1];
      this.image_prompt_str = "";
      this.input_parameters.image_prompt = "";

      this.modelTextShortNames.forEach((v) => {
        this.runGenerateModel(this.modelShort2Index[v], v);
      });
    },
    async runGenerateAllImage() {
      console.log("launch runGenerateAllImage");
      if (this.input_parameters.withImage) {
        while (this.image_prompt_str == "") {
          await this.sleep(2000);
          console.log("sleep, wait for article_strs to be filled");
        }

        console.log('before images', this.article_strs, this.image_prompt_str)

        this.modelImageShortNames.forEach((v) => {
          this.runGenerateModel(this.modelShort2Index[v], v);
        });
      }
    },
    stopGenerateAll() {
      this.stopGenerateAllText();
      this.stopGenerateAllImage();
    },
    stopGenerateAllText() {
      console.log("launch stopGenerateAllImage");
      this.modelTextShortNames.forEach((v) => {
        this.stopFlags[this.modelShort2Index[v]] = true;
      });
    },
    stopGenerateAllImage() {
      console.log("launch stopGenerateAllImage");
      this.modelImageShortNames.forEach((v) => {
        this.stopFlags[this.modelShort2Index[v]] = true;
      });
    },
    endGeneration(modelId) {
      this.stopFlags[modelId] = true;
    },
    getSmlMetrics(modelId) {
      const self = this;
      let text = this.article_strs[modelId];

      this.axios({
        method: "post",
        url: this.$hostname_ba + "/smm/get_sml_metrics",
        params: {
          text: text
        },
      }).then(function (response) {
        let data = response.data["scores"];

        console.log(data);

        self.sml_metrics[modelId] = [];
        self.sml_metrics[modelId].push(data[0]);
        self.sml_metrics[modelId].push(data[1]);
        self.sml_metrics[modelId].push(data[2]);

        console.log(self.sml_metrics);

        return data;
      });
    },
    async runGenerateModel(modelId, modelName, startOver = true) {
      this.stopFlags[modelId] = false;
      this.article_strs[modelId] = "";
      this.promptIndex[modelId] = startOver ? 0 : this.promptIndex[modelId];

      console.log("input_parameters", this.input_parameters);

      const evtSource = new EventSource(
        this.$hostname_ba +
        "/smm/sse-request-smm-" +
        modelName +
        "?hash_id=" +
        this.hash +
        "&user_prompt=" +
        JSON.stringify(this.input_parameters.prompt) +
        "&image_prompt=" +
        JSON.stringify(this.input_parameters.image_prompt) +
        "&text_style=" +
        this.input_parameters.styleText +
        "&image_style=" +
        this.input_parameters.styleImage +
        "&temperature=" +
        this.input_parameters.temperature / 100 +
        "&user_id=" +
        this.input_parameters.user_id
      );

      let that = this;

      function updateEvent(event) {
        console.log(event);
        let message = event.data;
        console.log("message: " + message + " ");
        if (!that.stopFlags[modelId]) {
          if (that.modelTextShortNames.includes(modelName)) {
            that.article_strs[modelId] =
              that.article_strs[modelId] + message + "\n\n";
          } else {
            console.log(modelId);
            console.log(message);
            that.article_strs[modelId] = message;
          }
        }
      }

      function closeEvent(event) {
        console.log("Handling end of " + modelName);
        that.endGeneration(modelId);
        event.currentTarget.close();
        // if (modelId <= 2) {
        if (modelId <= 1) {
          that.getSmlMetrics(modelId);
        }
      }

      function imageEvent(event) {
        console.log("Handling imageEvent");
        console.log(event.data);
        that.image_prompt_str = event.data;
        that.input_parameters.image_prompt = that.image_prompt_str;
      }

      evtSource.addEventListener("update_" + modelName, updateEvent);
      evtSource.addEventListener("end_" + modelName, closeEvent);
      if (modelName == "cgpt") {
        evtSource.addEventListener("image_prompt", imageEvent);
      }

    },
    copy_to_clipboard(n) {
      let data = this.article_strs[this.modelFull2Index[n]];
      if (this.modelTextFullNames.includes(n)) {
        navigator.clipboard.writeText(data);
      } else {
        console.log(data.slice("data:image/png;base64,".length));
        navigator.clipboard.write([
          new ClipboardItem({
            "image/png": this.b64toBlob(
              data.slice("data:image/png;base64,".length),
              "image/png"
            ),
          }),
        ]);
      }
    },
    download(n) {
      console.log(n);
      let data = this.article_strs[this.modelFull2Index[n]];
      var a = document.createElement("a");
      a.href = data;
      if (this.modelTextFullNames.includes(n)) {
        a.href = "data:txt;txt," + data;
        a.download = "text_" + n + ".txt";
      } else {
        a.href = data;
        a.download = "image_" + n + ".png";
      }
      a.click();
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
};
</script>

<style scoped>
/* Offset between elements */
* {
  --container-width: 1366px;
  --offset-xxs: 4px;
  --offset-xs: 8px;
  --offset-sm: 12px;
  --offset-md: 16px;
  --offset-lg: 20px;
  --offset-xl: 24px;
  --offset-xxl: 28px;
  --offset-huge: 32px;
  --offset-jumbo: 40px;
  --color-light-gray: #97a0af;
  --color-light-bg-outlet: #e6e7f0;
  --color-light-bg-dark: #ebecf2;
  --color-light-black: #232628;
  --color-light-red: #ff8f73;
  --color-light-yellow: #ffe380;
  --color-light-divider-light: var(--color-text-light-gray);
  --color-light-bg-light: #f4f5f7;
  --color-light-bg-accent: #d3d8e7;
  --color-light-bg-accent-card: #ffffff51;
  --color-light-text-gray: #6b778c;
  --color-light-bg-gray: var(--color-light-bg-light);
  --size-xxs: 4px;
  --size-xs: 8px;
  --size-sm: 12px;
  --size-md: 16px;
  --size-lg: 20px;
  --size-xl: 24px;
  --size-xxl: 28px;
  --size-huge: 32px;
  --size-jumbo: 40px;
  --font-fallback: "Verdana", "Roboto", sans-serif;
  --font-base: "SB Sans Text", var(--font-fallback);
  --font-caps: "SB Sans UI Caps", var(--font-base);
  --font-12: 400 12px / 14px var(--font-base);
  --font-14: 400 14px / 18px var(--font-base);
  --font-14-medium: 500 14px / 18px var(--font-base);
  --font-14-semibold: 600 14px / 18px var(--font-base);
  --font-16: 400 16px / 20px var(--font-base);
  --font-16-caps: 400 16px / 20px var(--font-caps);
  --font-16-bold: 700 16px / 20px var(--font-base);
  --font-18: 400 18px / 23px var(--font-base);
  --font-18-medium: 600 18px / 23px var(--font-base);
  --font-20: 400 20px / 25px var(--font-base);
  --font-20-medium: 500 20px / 25px var(--font-base);
  --font-24-medium: 500 24px / 30px var(--font-base);
  --font-20-semibold: 600 20px / 25px var(--font-base);
  --font-28-medium: 500 28px / 35px var(--font-base);
  --font-28-bold: 600 28px / 35px var(--font-base);
  --font-32-semibold: 600 32px / 40px var(--font-base);
  /* Caps UI */
  --font-12-caps: 400 12px / 14px var(--font-caps);
  --font-20-caps: 400 20px / 28px var(--font-caps);
  --color-text-black: var(--color-light-black);
  --color-text-light-red: #ffd7de;
  --color-text-light-gray: #dfe1e6;
  --color-text-very-light-gray: #b1bac9;
  --color-text-very-dark-gray: #313845;
  --color-text-gray: var(--color-light-text-gray);
  --color-text-dark-white: #d9dde3;
  --color-text-light-yellow: #fff1ca;
  --color-primary: #7b56e2;
  --color-primary-dark: #7351d4;
  --color-interactive: #563599;
  --color-gray: #5c5c5c;
  --color-black: #2c2c2c;
  --color-gray-dark: #dadde3;
  --color-white: #fff;
  --color-red: #f00;
  --color-error: #f57a87;
  --color-success: #8dd9be;
  --color-danger: #ff5630;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(var(--size-jumbo) + var(--size-xs));
  background-color: var(--color-black);
  padding: 0 var(--offset-jumbo);
}

.home {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--offset-sm) 0;
  border: 0;
  border-radius: 0;
  font: var(--font-16-bold);
  color: var(--color-text-dark-white);
  text-decoration: none;
}

body {
  margin: 0;
}

.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.link {
  position: relative;
  padding: var(--offset-sm) var(--offset-xl);
  height: 100%;
  border: none;
  border-radius: 0;
  text-decoration: none;
  font: var(--font-14-medium);
  color: var(--color-text-dark-white);
}

.speakers {
  display: flex;
  gap: 8px;
  align-items: center;
}

.speakers svg {
  height: var(--size-xs);
  transform: rotate(90deg);
  transition: rotate 0.25s ease-in-out;
  width: var(--size-xs);
}

.link:has(+ a)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - var(--offset-sm) * 2);
  background-color: var(--color-light-gray);
}

:where(button, a)[aria-disabled="true"],
button:disabled,
:global(.ant-btn-link:disabled) {
  pointer-events: none;
  background-color: transparent;
  color: var(--color-gray);

  &:global(.ant-btn:hover) {
    background-color: transparent;
    color: var(--color-gray);
  }
}

div.search {
  padding: 0;
  color: var(--color-text-dark-white);
  width: var(--size-lg);
  height: var(--size-lg);
  border: 0;
}

.link {
  display: flex;
  text-decoration: none;
  color: #d9dde3;
}

.link:hover {
  color: #b1b5c5;
}

.textMedium {
  font: 500 14px / 18px "SB Sans Text", "Verdana", "Roboto", sans-serif;
}

.titleBold {
  font: 600 32px / 40px "SB Sans Text", "Verdana", "Roboto", sans-serif;
}

.mainsmm {
  min-width: 390px;
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  margin: 0;
  padding: 0;
}

ul {
  margin-left: 24px;
  margin-top: 12px;
  line-height: 1.5rem;
}

h5 {
  /* width: 1000px; */
  white-space: pre-wrap;
  line-height: 1.2em;
  font-size: 1.2em;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Custom ui styles */

.prompt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
}

.prompt__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
}

.prompt__textarea:focus {
  border-color: #7b56e2;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.generation__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
  border-radius: 0.25rem;
}

.generation__textarea:hover {
  border-color: #7b56e2;
}

.model__title {
  color: #495057;
}

.prompt__btn {
  font-weight: 500;
  margin: 0;
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 11px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7b56e2;
  color: #fff;
  margin: 5px;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.prompt__btn-stop {
  position: relative;
  padding-left: 28px;
  color: #232628;
  background-color: #f4f5f7;
  border: 1px solid #f4f5f7;
}

.prompt__btn-stop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: #232628;
  z-index: 1;
  opacity: 0.25;
  transition: 0.3s ease-in-out;
}

.prompt__btn-stop:active {
  color: #493a6a;
  border-color: #493a6a;
}

.prompt__btn-stop:hover,
.prompt__btn-stop:focus-visible {
  color: #7b56e2;
  border-color: #7b56e2;
}

.prompt__btn-stop:hover::before,
.prompt__btn-stop:focus-visible::before {
  background-color: #7b56e2;
  opacity: 0.9;
}

.prompt__btn-submit:active {
  background-color: #493a6a;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.fb-chat {
  width: 100px;
  line-height: 60px;
  display: block;
  border-radius: 30% / 50%;
  position: fixed;
  left: 50%;
  /*top: 50%;
  transform: translate(-50%, -50%);*/
}

.fb-chat__bubbles {
  text-align: center;
}

.fb-chat__bubbles span {
  display: inline-block;
  background-color: #b6b5ba;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 5px;
  animation: bob 2s infinite;
}

.fb-chat__bubbles span:nth-child(1) {
  animation-delay: -1s;
}

.fb-chat__bubbles span:nth-child(2) {
  animation-delay: -0.85s;
}

.fb-chat__bubbles span:nth-child(3) {
  animation-delay: -0.7s;
  margin-right: 0;
}

@keyframes bob {
  10% {
    transform: translateY(-10px);
    background-color: #9e9da2;
  }

  50% {
    transform: translateY(0);
    background-color: #b6b5ba;
  }
}

.feather-small {
  width: 18px;
  height: 18px;
}

.con-select {
  min-width: 250px;
}</style>
